import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/Container"
import SEO from "../components/seo"
import Hero from "../components/Hero"
// import CtaRightRailAccent from "../components/CtaRightRailAccent"
import client from '../api/contentful'

const Updates = () => {
  const[content, setContent] = useState();
  let updatesList;

  useEffect(() => {
    client.getEntries({
        content_type: 'update',
    })
    .then(entry => setContent(entry.items))
    .catch(err => console.log(err));
  }, [])


  const dateOptions = {year: 'numeric', month: 'long', day: 'numeric' };

  if(content) {
    updatesList = content.map((item, index)  => {
        let formatDate = Date.parse(item.fields.date)
        formatDate = new Intl.DateTimeFormat('en-US', dateOptions).format(formatDate)

        return (
          <Link key={index} className="d-block py-4 border-bottom" to={`/updates/${item.fields.slug}/`}><h2 className="vertical-listing-title">{item.fields.title}</h2>
            {item.fields.date && <p className="mb-0 description">{formatDate}</p>}
          </Link>
        )
    })
  }

  return (
    <Layout>
      <SEO title="Updates" />
      {content &&
      <Container>
        <section className="page-content col tutorials">
            <div className="row">
                <Hero title="Updates" description="Quick guides to help you work efficiently in UTA's Sitecore CMS"/>

                <div className="col-lg-9 mx-auto vertical-listing">
                    {updatesList}
                </div>

                {/* <div className="col-lg-3">
                    <CtaRightRailAccent headline="Sign up for Training" description="From Sitecore to Cascade 8 to Campus Press, get the right training for you." url="https://webapps.uta.edu/oittraining/" linkText="Upcoming Classes"/>
                </div> */}
                
            </div>
        </section>
        </Container>}
      
    </Layout>
  )
}

export default Updates
